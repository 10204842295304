<template>
	<ul class="menu">
		<li class="menu-item">
			<div class="tile tile-centered">
				<div class="tile-icon">
					<img class="avatar" src="@/assets/img/avatar.jpg" alt="Avatar">
				</div>
				<div class="tile-content">{{ usuario }}</div>
			</div>
		</li>
		<li class="divider" :data-content="tipo"></li>
		<li class="menu-item">
			<router-link v-for="(i, k) of verMenu" :key="k" :to="i.href">
				{{ i.nombre }}
			</router-link>
		</li>
		<li class="divider"></li>
		<li class="menu-item">
			<router-link :to="{ name: 'Perfil' }">
				Mi Perfil
			</router-link>
		</li>
		<li class="menu-item">
			<a @click.prevent="salir()" href="#">Cerrar Sesión</a>
		</li>
	</ul>
</template>

<script>
	export default {
		name: 'Menu',
		data() {
			return {
				menu: [
					{
						nombre: 'Inicio',
						href: { name: 'Inicio' },
						tipo: 2
					},
					{
						nombre: 'Ordenes',
						href: { name: 'SV_Ordenes' },
						tipo: 2
					},
					{
						nombre: 'Clientes',
						href: { name: 'SV_Clientes' },
						tipo: 2
					},
					{
						nombre: 'Servicios',
						href: { name: 'SV_Servicios' },
						tipo: 2
					},
					{
						nombre: 'Técnicos',
						href: { name: 'SV_Tecnicos' },
						tipo: 2
					},
					{
						nombre: 'Mis Ordenes',
						href: { name: 'TN_Ordenes' },
						tipo: 3
					},
					// {
					// 	nombre: 'Mis Clientes',
					// 	href: '/tecnicos/clientes',
					// 	tipo: 3
					// }
				],
			};
		},
		methods: {
			salir() {
				this.$store.commit('setUser', {});
				this.$store.commit('crearAxios', '');
				localStorage.removeItem('WuipiToken');
				this.$router.push('/ingresar');
			}
		},
		computed: {
			usuario() {
				return Object.keys(this.$store.state.user).length === 0
					? 'Usuario'
					: `${this.$store.state.user.nombre}`;
			},
			tipo() {
				return Object.keys(this.$store.state.user).length === 0
					? 'Tipo'
					: this.$store.state.user.tipo_nombre;
			},
			verMenu() {
				let tipo = this.$store.state.user.tipo;
				if (tipo == 1) { return this.menu; }
				return this.menu.filter(el => el.tipo == tipo);
			}
		}
	}
</script>
