<template>
	<main class="container grid-xl">
		<div class="columns">
			<div class="column hide-md col-3">
				<section class="card">
					<div class="card-image">
						<img src="@/assets/img/lecheria.jpg" class="img-responsive">
					</div>
					<Menu />
				</section>
			</div>
			<div class="column show-md col-12">
				<div class="card py-2">
					<details class="accordion">
						<summary class="accordion-header c-hand">
							<i class="icon icon-arrow-right mr-1"></i> <strong>Menú</strong>
						</summary>
						<div class="accordion-body">
							<Menu />
						</div>
					</details>
				</div>
			</div>
			<div class="column col-md-12 col-9">
				<transition name="fade" mode="out-in">
					<router-view />
				</transition>
			</div>
		</div>
	</main>
</template>

<script>
	import Menu from '@/components/Menu.vue';
	export default {
		name: 'Layout_Dashboard',
		components: { Menu },
	};
</script>

<style scoped>
	main {
		margin-top: 1rem;
	}
	details[open] summary ~ * {
		animation: sweep .25s ease-in-out;
	}
	@keyframes sweep {
		0%    {opacity: 0; margin-top: -15px}
		100%  {opacity: 1; margin-top: 0px}
	}
</style>
